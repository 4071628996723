import React, { useEffect, useState } from "react";
import Menu from "../../components/menu/menu.jsx";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import api from "../../services/api.js";
import Navbar from "../../components/navbar/navbar";
import "./contato.css";
import ContatoForm from "./ContatoForm"; // Formulário inline para edição/cadastro
import debounce from "lodash.debounce";

function Contato() {
  // Estados para listagem, busca e formulário
  const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
  const [contatos, setContatos] = useState([]);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [dadosContato, setDadosContato] = useState(null);
  const [etapa, setEtapa] = useState("");
  const [menuAberto, setMenuAberto] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);

  useEffect(() => {
    listarContatos(localStorage.getItem("id_empresa"), etapa, 1, qtd_reg_pagina);
  }, []);

  // Busca com debounce: ao digitar, a busca é executada após 500ms de inatividade
  const debouncedSearch = debounce((valor) => {
    listarContatos(localStorage.getItem("id_empresa"), valor, 1, qtd_reg_pagina);
  }, 500);

  function handleInputChange(event) {
    setEtapa(event.target.value);
    debouncedSearch(event.target.value);
  }

  // Busca ao clicar no botão
  function handleSearchClick() {
    listarContatos(localStorage.getItem("id_empresa"), etapa, 1, qtd_reg_pagina);
  }

  function toggleMenu() {
    setMenuAberto(!menuAberto);
  }

  // Abre o formulário para edição ou novo cadastro
  function openFormContato(id) {
    if (id > 0) {
      api.get(`/v1/contato/${id}`)
        .then(response => {
          setDadosContato(response.data[0] || {});
        })
        .catch(err => {
          console.log("Erro ao buscar contato:", err);
        });
    } else {
      setDadosContato({
        id: 0,
        nome: "",
        telefone: "",
        email: ""
        // Adicione outros campos conforme necessário
      });
    }
  }

  function closeFormContato() {
    setDadosContato(null);
  }

  function salvarContato(contato) {
    if (contato.id > 0) {
      api.put(`/v1/contato/${contato.id}`, contato)
        .then(() => {
          closeFormContato();
          listarContatos(localStorage.getItem("id_empresa"), etapa, 1, qtd_reg_pagina);
        })
        .catch(() => {
          alert("Erro ao salvar os dados.");
        });
    } else {
      api.post(`/v1/contato`, contato)
        .then(() => {
          closeFormContato();
          listarContatos(localStorage.getItem("id_empresa"), etapa, 1, qtd_reg_pagina);
        })
        .catch(() => {
          alert("Erro ao salvar os dados.");
        });
    }
  }

  function excluir(id) {
    confirmAlert({
      title: 'Exclusão',
      message: 'Confirma exclusão do contato?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            api.delete(`/v1/contato/${id}`)
              .then(() => {
                listarContatos(localStorage.getItem("id_empresa"), etapa, 1, qtd_reg_pagina);
              })
              .catch(() => {
                alert("Erro ao excluir contato");
              });
          }
        },
        { label: 'Não', onClick: () => {} }
      ]
    });
  }

  function exportarContato(id) {
    api.post(`/v1/contato/exportar/${id}`)
      .then(() => {
        alert("Exportado com sucesso!");
      })
      .catch(() => {
        alert("Erro ao exportar contato");
      });
  }

  // Função listarContatos conforme seu padrão
  function listarContatos(empresa, busca, pagina, qtd_reg_pagina) {
    const idEmpresa = parseInt(localStorage.getItem('id_empresa'), 10);
    api.get("/v1/contato/listar", { params: { empresa: idEmpresa, etapa, pagina, qtd_reg_pagina: qtd_reg_pagina } })
      .then(resp => {
        setTotalRegistros(resp.data.total_registros);
        setContatos(resp.data.dados);
      })
      .catch(() => {
        setTotalRegistros(0);
        setContatos([]);
        alert("Erro ao carregar contatos");
      });
  }

  const columns = [
    { name: 'Código', selector: row => row.id, sortable: true, width: "120px" },
    { name: 'Nome', selector: row => row.apelido, sortable: true },
    { name: 'Telefone', selector: row => row.telefone, sortable: true },
    { name: 'Email', selector: row => row.email, sortable: true },
    {
      name: 'Ações',
      cell: row => (
        <div className="table-actions">
          <button onClick={() => openFormContato(row.id)} className="btn-edit">
            <i className="bi bi-pencil-square"></i>
          </button>
          <button onClick={() => excluir(row.id)} className="btn-delete">
            <i className="bi bi-trash3-fill"></i>
          </button>
          <button onClick={() => exportarContato(row.id)} className="btn-export">
            <i className="bi bi-download"></i>
          </button>
        </div>
      ),
      width: "150px",
      right: true
    }
  ];

  return (
    <div className="app-container">
      <Navbar />

      <button className="menu-toggle" onClick={toggleMenu}>
        {menuAberto ? "Fechar Menu" : "Abrir Menu"}
      </button>

      <div className="main-layout">
        <aside className={`sidebar ${menuAberto ? "open" : "closed"}`}>
          <Menu page="contato" />
        </aside>

        <main className="content">
          <div className="contato-header">
            <h2>Contatos</h2>
            <div className="header-search">
              <input
                type="text"
                className="search-input"
                placeholder="Buscar Contato"
                value={etapa}
                onChange={handleInputChange}
              />
              <button onClick={handleSearchClick} className="search-button">
                <i className="bi bi-search"></i>
              </button>
            </div>
            <button className="btn btn-success" onClick={() => openFormContato(0)}>
              Novo Contato
            </button>
          </div>

          {!dadosContato ? (
            <div className="card">
              <DataTable
                columns={columns}
                data={contatos}
                pagination
                paginationTotalRows={totalRegistros}
                highlightOnHover
                responsive
              />
            </div>
          ) : (
            <ContatoForm
              dados_contato={dadosContato}
              onCancel={closeFormContato}
              onSave={salvarContato}
            />
          )}
        </main>
      </div>
    </div>
  );
}

export default Contato;
