import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard.jsx";
import Login from "./pages/login/login.jsx";
import Contatos from "./pages/contato/contato.jsx";
import PrePaciente from "./pages/pacientepre/pacientepre.jsx";
import Paciente from "./pages/paciente/paciente.jsx";
import PrivateRoute from "./components/private-route/private-route.jsx";

function Rotas() {
    return <BrowserRouter>
        <Routes>
            
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />

            <Route path="/dashboard" element={<Dashboard />
            } />
            <Route path="/contato" element={
                    <Contatos />
            } />

            <Route path="/pacientepre" element={
                <PrivateRoute>
                    <PrePaciente />
                </PrivateRoute>
            } />

            <Route path="/paciente" element={
                <PrivateRoute>
                    <Paciente />
                </PrivateRoute>
            } />

        </Routes>
    </BrowserRouter>
}

export default Rotas;