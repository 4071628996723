import Menu from "../../components/menu/menu.jsx";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import api_horse from "../../services/api_horse.js";
import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/navbar";
import "./pacientepre.css";
import PacientePreForm from "./PacientePreForm";
import debounce from "lodash.debounce";

function PrePaciente() {
    const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
    const [dados, setDados] = useState([]);
    const [total_registros, setTotalRegistros] = useState(0);
    const [dados_pacientepre, setDados_Pacientepre] = useState(null);
    const [busca, setBusca] = useState("");
    const [menuAberto, setMenuAberto] = useState(false); // Controle do menu mobile

    useEffect(() => {
        ListarNegocios(localStorage.getItem("id_empresa"), busca, 1, qtd_reg_pagina);
    }, []);

    const debouncedSearch = debounce((valor) => {
        ListarNegocios(localStorage.getItem("id_empresa"), valor, 1, qtd_reg_pagina);
    }, 500);

    function handleInputChange(event) {
        setBusca(event.target.value);
        debouncedSearch(event.target.value);
    }

    function handleSearchClick() {
        ListarNegocios(localStorage.getItem("id_empresa"), busca, 1, qtd_reg_pagina);
    }

    function toggleMenu() {
        setMenuAberto(!menuAberto);
    }

    function OpenFormPacientePre(id) {
        if (id > 0) {
            api_horse.get(`/v1/pacientepre/${id}`)
                .then(response => {
                    setDados_Pacientepre(response.data[0] || {});
                })
                .catch(err => {
                    console.log("Erro ao buscar pré-paciente:", err);
                });
        } else {
            setDados_Pacientepre({
                id: 0, telefone: "", dor: "", nivel_interesse: "", nome: "", cpf: "", medico: "", obs: "", avaliado_por_medico: "",
                tipoavaliacao: "", tecnica: "", desconto_por: "", valor_venda: 0, email: "", apelido: ""
            });
        }
    }

    function CloseFormPacientePre() {
        setDados_Pacientepre(null);
    }

    function SalvarContato(paciente) {
        api_horse.put(`/v1/pacientepre/${paciente.id}`, paciente)
            .then(() => {
                CloseFormPacientePre();
                ListarNegocios(localStorage.getItem("id_empresa"), busca, 1, qtd_reg_pagina);
            })
            .catch(() => {
                alert("Erro ao salvar os dados.");
            });
    }

    function Excluir(id) {
        confirmAlert({
            title: 'Exclusão',
            message: 'Confirma exclusão do pré-paciente?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        api_horse.delete(`/negocios/${id}`)
                            .then(() => {
                                ListarNegocios(localStorage.getItem("id_empresa"), busca, 1, qtd_reg_pagina);
                            })
                            .catch(() => {
                                alert("Erro ao excluir pré-paciente");
                            });
                    }
                },
                { label: 'Não', onClick: () => { } }
            ]
        });
    }

    function ListarNegocios(empresa, etapa, pagina, qtd_reg_pagina) {
        api_horse.get("/v1/pacientepre/listar", { params: { empresa, etapa, pagina, qtd_reg_pagina } })
            .then((resp) => {
                setTotalRegistros(resp.data.total_registros);
                setDados(resp.data.dados);
            })
            .catch(() => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar pré-paciente");
            });
    }

    const columns = [
        { name: 'Código', selector: row => row.id, sortable: true, width: "120px" },
        { name: 'Nome', selector: row => row.nome, sortable: true, compact: true },
        { name: 'CPF', selector: row => row.cpf, sortable: true },
        {
            name: 'Ações',
            cell: (row) => (
                <div className="table-actions">
                    <button onClick={() => OpenFormPacientePre(row.id)} className="btn-edit">
                        <i className="bi bi-pencil-square"></i>
                    </button>
                    <button onClick={() => Excluir(row.id)} className="btn-delete">
                        <i className="bi bi-trash3-fill"></i>
                    </button>
                </div>
            ),
            width: "120px",
            right: true
        }
    ];

    return (
        <div className="app-container">
            <Navbar />
            
            {/* Botão para abrir/fechar menu no mobile */}
            <button className="menu-toggle" onClick={toggleMenu}>
                {menuAberto ? "Fechar Menu" : "Abrir Menu"}
            </button>

            <div className="main-layout">
                {/* Esconde o menu em telas pequenas */}
                <aside className={`sidebar ${menuAberto ? "open" : "closed"}`}>
                    <Menu page="negocios" />
                </aside>
                
                <main className="content">
                    <div className="prepaciente-header">
                        <h2>Pré-Pacientes</h2>

                        {/* Barra de busca com atualização automática */}
                        <div className="header-search">
                            <input
                                type="text"
                                className="search-input"
                                placeholder="Buscar Pré-Paciente"
                                value={busca}
                                onChange={handleInputChange}
                            />
                            <button onClick={handleSearchClick} className="search-button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>

                        <button className="btn btn-success" onClick={() => OpenFormPacientePre(0)}>Novo Pré-Paciente</button>
                    </div>

                    {!dados_pacientepre ? (
                        <div className="card">
                            <DataTable columns={columns} data={dados} pagination paginationTotalRows={total_registros} />
                        </div>
                    ) : (
                        <PacientePreForm dados_pacientepre={dados_pacientepre} onCancel={CloseFormPacientePre} onSave={SalvarContato} />
                    )}
                </main>
            </div>
        </div>
    );
}

export default PrePaciente;
