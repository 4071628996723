import { useState, useEffect } from "react";
import MenuPaciente from "../../components/menu/menuPaciente";

function PacienteForm({ dados_paciente, onCancel, onSave }) {
    const [paciente, setPaciente] = useState(dados_paciente);

    useEffect(() => {
        setPaciente(dados_paciente);
    }, [dados_paciente]);

    function handleChange(e) {
        setPaciente({ ...paciente, [e.target.name]: e.target.value });
    }

    return (
        <div className="container-fluid d-flex flex-wrap">

            {/* Conteúdo principal */}
            <div className="container p-4 flex-grow-1">
                <div className="card shadow-lg p-4">
                    <h3 className="text-center">{paciente.id > 0 ? "Editar Paciente" : "Novo Paciente"}</h3>

                    {/* Seção: Informações Básicas */}
                    <fieldset className="border p-3 mb-4">
                        <legend className="w-auto text-lg font-semibold">Informações Básicas</legend>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Nome Completo:</label>
                                <input type="text" name="nome" value={paciente.nome || ""} onChange={handleChange} className="form-control" required />

                                <label>Nome Social:</label>
                                <input type="text" name="nome_social" value={paciente.nome_social || ""} onChange={handleChange} className="form-control" />

                                <label>CPF:</label>
                                <input type="text" name="cpf" value={paciente.cpf || ""} onChange={handleChange} className="form-control" required />

                                <label>RG:</label>
                                <input type="text" name="rg" value={paciente.rg || ""} onChange={handleChange} className="form-control" />
                            </div>

                            <div className="col-md-6">
                                <label>Data de Nascimento:</label>
                                <input type="date" name="data_nascimento" value={paciente.data_nascimento || ""} onChange={handleChange} className="form-control" />

                                <label>Sexo:</label>
                                <select name="sexo" value={paciente.sexo || ""} onChange={handleChange} className="form-control">
                                    <option value="">Selecione</option>
                                    <option value="M">Masculino</option>
                                    <option value="F">Feminino</option>
                                </select>

                                <label>Nome da Mãe:</label>
                                <input type="text" name="mae" value={paciente.mae || ""} onChange={handleChange} className="form-control" />
                            </div>
                        </div>
                    </fieldset>

                    {/* Seção: Contato */}
                    <fieldset className="border p-3 mb-4">
                        <legend className="w-auto text-lg font-semibold">Contato</legend>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Telefone:</label>
                                <input type="text" name="telefone" value={paciente.telefone || ""} onChange={handleChange} className="form-control" />

                                <label>Celular:</label>
                                <input type="text" name="celular" value={paciente.celular || ""} onChange={handleChange} className="form-control" />
                            </div>

                            <div className="col-md-6">
                                <label>Email:</label>
                                <input type="email" name="email" value={paciente.email || ""} onChange={handleChange} className="form-control" />
                            </div>
                        </div>
                    </fieldset>

                    {/* Seção: Endereço */}
                    <fieldset className="border p-3 mb-4">
                        <legend className="w-auto text-lg font-semibold">Endereço</legend>
                        <div className="row">
                            <div className="col-md-6">
                                <label>CEP:</label>
                                <input type="text" name="cep" value={paciente.cep || ""} onChange={handleChange} className="form-control" />

                                <label>Rua:</label>
                                <input type="text" name="rua" value={paciente.rua || ""} onChange={handleChange} className="form-control" />

                                <label>Número:</label>
                                <input type="text" name="numero" value={paciente.numero || ""} onChange={handleChange} className="form-control" />
                            </div>

                            <div className="col-md-6">
                                <label>Bairro:</label>
                                <input type="text" name="bairro" value={paciente.bairro || ""} onChange={handleChange} className="form-control" />

                                <label>Cidade:</label>
                                <input type="text" name="cidade" value={paciente.cidade || ""} onChange={handleChange} className="form-control" />

                                <label>Estado:</label>
                                <input type="text" name="estado" value={paciente.estado || ""} onChange={handleChange} className="form-control" />
                            </div>
                        </div>
                    </fieldset>

                    {/* Seção: Informações Adicionais */}
                    <fieldset className="border p-3 mb-4">
                        <legend className="w-auto text-lg font-semibold">Informações Adicionais</legend>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Profissão:</label>
                                <input type="text" name="profissao" value={paciente.profissao || ""} onChange={handleChange} className="form-control" />
                            </div>

                            <div className="col-md-6">
                                <label>Código Bônus:</label>
                                <input type="text" name="codigo_bonus" value={paciente.codigo_bonus || ""} onChange={handleChange} className="form-control" />
                            </div>
                        </div>
                    </fieldset>

                    {/* Botões */}
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-secondary me-2" onClick={onCancel}>Cancelar</button>
                        <button type="submit" className="btn btn-success" onClick={() => onSave(paciente)}>Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PacienteForm;
