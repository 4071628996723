import axios from "axios";

const URL = "https://api.glas.com.br:215/srv";
//const URL = "http://localhost:213";

const api_horse = axios.create({
    baseURL: URL, // URL base do backend
    timeout: 5000, // Timeout opcional
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export default api_horse;