// src/pages/dashboard/Dashboard.jsx
import React from "react";
import Navbar from "../../components/navbar/navbar";
import Menu from "../../components/menu/menu.jsx";
import "./dashboard.css";
import {
  BarChart, Bar, PieChart, Pie, Cell,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from "recharts";

const Dashboard = () => {
  const barData = [
    { name: "Sanos", exames: 12 },
    { name: "Glas", exames: 8 },
    { name: "Dermato", exames: 5 },
  ];

  const pieData = [
    { name: "Pré-paciente", value: 45 },
    { name: "Confirmado", value: 30 },
    { name: "Pendente", value: 25 },
  ];

  const COLORS = ["#3b82f6", "#10b981", "#f59e0b"];

  return (
    <>
      <Navbar />
      <div className="dashboard-container">
        <Menu />
        <main className="dashboard-content">
          <h2 className="dashboard-title">Olá, seja bem-vindo!</h2>

          {/* Cards estatísticos */}
          <section className="dashboard-stats">
            <div className="stat-card">
              <div className="stat-icon pi pi-user-plus" />
              <div className="stat-content">
                <h3>Pré-Pacientes</h3>
                <p>34 cadastrados hoje</p>
              </div>
            </div>
            <div className="stat-card">
              <div className="stat-icon pi pi-calendar" />
              <div className="stat-content">
                <h3>Agendamentos</h3>
                <p>15 confirmados</p>
              </div>
            </div>
            <div className="stat-card">
              <div className="stat-icon pi pi-dollar" />
              <div className="stat-content">
                <h3>Faturamento</h3>
                <p>R$ 12.500,00</p>
              </div>
            </div>
            <div className="stat-card">
              <div className="stat-icon pi pi-whatsapp" />
              <div className="stat-content">
                <h3>Contatos WhatsApp</h3>
                <p>48 mensagens novas</p>
              </div>
            </div>
          </section>

          {/* Gráficos */}
          <section className="dashboard-charts">
            <div className="chart-card">
              <h4>Exames por Unidade</h4>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart data={barData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="exames" fill="#3b82f6" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="chart-card">
              <h4>Status de Pré-Pacientes</h4>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                  <Pie
                    data={pieData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label
                  >
                    {pieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </section>

          {/* Notificações */}
          <section className="dashboard-notifications">
            <h4>Notificações</h4>
            <div className="notification-card success">
              <i className="pi pi-check-circle" />
              Novo pré-paciente cadastrado: <strong>Ana Souza</strong>
            </div>
            <div className="notification-card warning">
              <i className="pi pi-clock" />
              3 agendamentos ainda não confirmados
            </div>
            <div className="notification-card info">
              <i className="pi pi-info-circle" />
              2 novos contatos no WhatsApp aguardando resposta
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default Dashboard;
