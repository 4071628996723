import React, { useState, useEffect } from "react";
import { cpf as cpfValidator } from "cpf-cnpj-validator";
import InputMask from "react-input-mask";


function ContatoForm({ dados_contato, onCancel, onSave }) {
  const [contato, setContato] = useState(dados_contato);
  const [mensagem, setMensagem] = useState("");

  useEffect(() => {
    setContato(dados_contato);
  }, [dados_contato]);

  function handleChange(e) {
    setContato({ ...contato, [e.target.name]: e.target.value });
  }

  function handleSave() {
    // Aqui você pode adicionar validações se necessário (por exemplo, validação de CPF)
    onSave(contato);
  }

  return (
    <div className="form-container">
      <h3>{contato.id > 0 ? "Editar Contato" : "Novo Contato"}</h3>

      <div className="row">
        {/* Primeira Coluna */}
        <div className="col-md-6 mb-2">
          <label>Nome Contato:</label>
          <input
            type="text"
            name="nome"
            value={contato.nome || ""}
            onChange={handleChange}
            className="form-control"
            placeholder="Digite o nome"
          />

          <label>Telefone Celular:</label>
          <InputMask
            mask="+55 (99) 99999-9999"
            name="telefone"
            value={contato.telefone || ""}
            onChange={handleChange}
            className="form-control"
            placeholder="Digite o telefone"
          />

          <label>Observação:</label>
          <input
            type="text"
            name="observacao"
            value={contato.observacao || ""}
            onChange={handleChange}
            className="form-control"
            placeholder="Observação"
          />

          <label>Avaliado por:</label>
          <select
            name="medico"
            value={contato.medico || ""}
            onChange={handleChange}
            className="form-control"
          >
            <option value="">Selecione</option>
            <option value="AVALIADO THAIS">Avaliado Thais</option>
            <option value="AVALIADO QUEMELA">Avaliado Quemela</option>
            <option value="AVALIADO PALOMA">Avaliado Paloma</option>
            <option value="AVALIADO JOÃO">Avaliado João</option>
            <option value="AVALIADO LUANA">Avaliado Luana</option>
            <option value="AVALIADO ANA PAULA">Avaliado Ana Paula</option>
            <option value="AVALIADO LEONORA">Avaliado Leonora</option>
            <option value="AVALIADO FERNANDA">Avaliado Fernanda</option>
          </select>

          <label>Técnica:</label>
          <input
            type="text"
            name="tecnica"
            value={contato.tecnica || ""}
            onChange={handleChange}
            className="form-control"
            placeholder="Técnica"
          />

          <label>Valor Venda:</label>
          <input
            type="text"
            name="valor_venda"
            value={contato.valor_venda || ""}
            onChange={handleChange}
            className="form-control"
            placeholder="Valor Venda"
          />
        </div>

        {/* Segunda Coluna */}
        <div className="col-md-6 mb-2">
          <label>CPF:</label>
          <input
            type="text"
            name="cpf"
            value={contato.cpf || ""}
            onChange={handleChange}
            className="form-control"
            placeholder="CPF"
          />

          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={contato.email || ""}
            onChange={handleChange}
            className="form-control"
            placeholder="Email"
          />

          <label>Tipo Avaliação:</label>
          <input
            type="text"
            name="tipoavaliacao"
            value={contato.tipoavaliacao || ""}
            onChange={handleChange}
            className="form-control"
            placeholder="Tipo Avaliação"
          />

          <label>Nível de Interesse:</label>
          <input
            type="text"
            name="nivel_interesse"
            value={contato.nivel_interesse || ""}
            onChange={handleChange}
            className="form-control"
            placeholder="Nível de Interesse"
          />

          <label>Dor:</label>
          <input
            type="text"
            name="dor"
            value={contato.dor || ""}
            onChange={handleChange}
            className="form-control"
            placeholder="Dor"
          />

          <label>Desconto por:</label>
          <input
            type="text"
            name="desconto_por"
            value={contato.desconto_por || ""}
            onChange={handleChange}
            className="form-control"
            placeholder="Desconto por"
          />
        </div>
      </div>

      {mensagem && (
        <p className="alert alert-info text-center">{mensagem}</p>
      )}

      <div className="button-group">
        <button className="btn btn-success" onClick={handleSave}>
          Salvar
        </button>
        <button className="btn btn-secondary" onClick={onCancel}>
          Cancelar
        </button>
      </div>
    </div>
  );
}

export default ContatoForm;
