import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import LogoutIcon from "../../assets/logout.png"; // Importe a imagem de logout
import "./navbar.css";
import useSessionTimeout from "../../services/useSessionTimeout.jsx";
import api from "../../services/api.js";

const Navbar = (props) => {
  const navigate = useNavigate();
  const sessionActive = useSessionTimeout(1800000); // 30 minutos
  const [showDropdown, setShowDropdown] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState(localStorage.getItem("empresa") || "");
  const [loading, setLoading] = useState(true); // Estado para indicar carregamento

  useEffect(() => {
    if (!sessionActive) {
      alert("Sua sessão expirou! Faça login novamente.");
      localStorage.clear();
      window.location.href = "/login";
    }
  }, [sessionActive]);

  useEffect(() => {
    const fetchEmpresas = async () => {
      try {
        const usuarioId = localStorage.getItem("id_usuario");
        if (!usuarioId) {
          console.error("ID do usuário não encontrado no localStorage.");
          setLoading(false);
          return;
        }

        console.log("Buscando empresas para o usuário:", usuarioId);

        const response = await api.get(`/v1/empresa/listar?usuario=${usuarioId}`);
        console.log("Resposta da API:", response.data);

        if (Array.isArray(response.data)) {
          setEmpresas(response.data);

          // Define a empresa padrão inicialmente salva no localStorage
          const empresaPadrao = response.data.find((e) => e.default === 1)?.nome || "";
          setSelectedEmpresa(empresaPadrao);
          localStorage.setItem("empresa", empresaPadrao);
        } else {
          console.error("Resposta da API não é um array:", response.data);
        }
      } catch (error) {
        console.error("Erro ao buscar empresas:", error);
      } finally {
        setLoading(false); // Finaliza o carregamento
      }
    };

    fetchEmpresas();
  }, []);

  const handleEmpresaClick = async (empresaId, empresaNome) => {
    try {
      const usuarioId = localStorage.getItem("id_usuario");
      if (!usuarioId) {
        console.error("ID do usuário não encontrado no localStorage.");
        return;
      }

      console.log("Atualizando empresa padrão:", empresaId, empresaNome);

      // Chama a API para atualizar a empresa padrão
      await api.put(`/v1/empresa/atualizarPadrao?usuario=${usuarioId}&empresa=${empresaId}`);

      // Atualiza o estado local e o localStorage
      setSelectedEmpresa(empresaNome);
      localStorage.setItem("empresa", empresaNome);
      localStorage.setItem("id_empresa", parseInt(empresaId),10);

      // Fecha o dropdown após a seleção
      setShowDropdown(false);

      console.log("Empresa padrão atualizada com sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar empresa padrão:", error);
      alert("Erro ao atualizar a empresa padrão. Tente novamente.");
    }
  };

  function logout() {
    localStorage.clear();
    navigate("/login");
  }

  return (
    <header className="app-navbar">
      <div className="navbar-container">
        {/* Logo */}
        <div className="navbar-left">
          <Link to="/dashboard" className="navbar-logo">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>

        {/* Usuário, Empresa e Logout */}
        <div className="navbar-right">
          <div className="user-and-company">
            <span className="navbar-usuario">{localStorage.getItem("usuario")}</span>
            <div className="empresa-wrapper">
              <button
                className="empresa-button"
                onClick={(e) => {
                  e.stopPropagation(); // Impede que o clique propague para outros elementos
                  setShowDropdown(!showDropdown);
                }}
              >
                Empresa: {selectedEmpresa || "Carregando..."}
                <i className="pi pi-angle-down"></i>
              </button>
              {showDropdown && (
                <div className="empresa-dropdown">
                  {loading ? (
                    <div className="dropdown-item">Carregando empresas...</div>
                  ) : empresas.length > 0 ? (
                    empresas.map((empresa) => (
                      <div
                        key={empresa.empresa}
                        className={`dropdown-item ${
                          selectedEmpresa === empresa.nome ? "selected" : ""
                        }`}
                        onClick={() => handleEmpresaClick(empresa.empresa, empresa.nome)}
                      >
                        {empresa.nome}
                      </div>
                    ))
                  ) : (
                    <div className="dropdown-item">Nenhuma empresa encontrada</div>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* Botão de Logout */}
          <button className="logout-button" onClick={logout}>
            <img src={LogoutIcon} alt="Logout" />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Navbar;