import { useState, useEffect } from "react";
import api_horse from "./../../services/api_horse";


function PacientePreForm({ dados_pacientepre, onCancel, onSave }) {
    const [paciente, setPaciente] = useState(dados_pacientepre);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const options = ["Risco Cirúrgico", "Minoxidil", "Dutasterida"];
    const [mensagem, setMensagem] = useState("");

    useEffect(() => {
        setPaciente(dados_pacientepre);
    }, [dados_pacientepre]);

    function handleChange(e) {
        setPaciente({ ...paciente, [e.target.name]: e.target.value });
    }

    function handleCheckboxChange(option) {
        setSelectedOptions((prev) =>
            prev.includes(option)
                ? prev.filter((item) => item !== option)
                : [...prev, option]
        );
    }

    function handleSave() {
        onSave(paciente);
    }

    function EnviaExame(id, telefone) {
        if (!telefone) {
            setMensagem("Telefone não disponível para envio.");
            return;
        }

        api_horse.get("/v1/pacientepre/enviazap", { params: { id, telefone } })
            .then(() => {
                setMensagem("WhatsApp enviado com sucesso!");
                setTimeout(() => setMensagem(""), 3000);
            })
            .catch(() => setMensagem("Erro ao enviar WhatsApp."));
    }

    function EnviaExameEmail(id, empresa, nome, email) {
        if (!email) {
            setMensagem("E-mail não disponível para envio.");
            return;
        }

        api_horse.get("/v1/pacientepre/enviaemail", { params: { id, empresa, nome, email } })
            .then(() => {
                setMensagem("E-mail enviado com sucesso!");
                setTimeout(() => setMensagem(""), 3000);
            })
            .catch(() => setMensagem("Erro ao enviar e-mail."));
    }

    return (
        <div className="form-container">
            <h3>{paciente.id > 0 ? "Editar Pré-Paciente" : "Novo Pré-Paciente"}</h3>

            <div className="row">
                <div className="col-md-6">
                    <label>Nome:</label>
                    <input type="text" name="nome" value={paciente.nome} onChange={handleChange} className="form-control" />

                    <label>Telefone:</label>
                    <input type="text" name="telefone" value={paciente.telefone} onChange={handleChange} className="form-control" />

                    <label>Observação:</label>
                    <input type="text" name="obs" value={paciente.obs} onChange={handleChange} className="form-control" />

                    <label>Avaliado por:</label>
                    <select name="avaliado_por_medico" value={paciente.avaliado_por_medico} onChange={handleChange} className="form-control">
                        <option value="">Selecione</option>
                        <option value="Thais">Avaliado Thais</option>
                        <option value="Quemela">Avaliado Quemela</option>
                        <option value="Paloma">Avaliado Paloma</option>
                        <option value="João">Avaliado João</option>
                        <option value="Luana">Avaliado Luana</option>
                        <option value="Ana Paula">Avaliado Ana Paula</option>
                        <option value="Leonora">Avaliado Leonora</option>
                        <option value="Fernanda">Avaliado Fernanda</option>
                    </select>

                    <label>Técnica:</label>
                    <input type="text" name="tecnica" value={paciente.tecnica} onChange={handleChange} className="form-control" />
                </div>

                <div className="col-md-6">
                    <label>CPF:</label>
                    <input type="text" name="cpf" value={paciente.cpf} onChange={handleChange} className="form-control" />

                    <label>Email:</label>
                    <input type="email" name="email" value={paciente.email} onChange={handleChange} className="form-control" />

                    <label>Tipo de Avaliação:</label>
                    <input type="text" name="tipoavaliacao" value={paciente.tipoavaliacao} onChange={handleChange} className="form-control" />

                    <label>Nível de Interesse:</label>
                    <input type="text" name="nivel_interesse" value={paciente.nivel_interesse} onChange={handleChange} className="form-control" />

                    <label>Dor:</label>
                    <input type="text" name="dor" value={paciente.dor} onChange={handleChange} className="form-control" />
                </div>
            </div>

            {mensagem && <p className="alert alert-info text-center">{mensagem}</p>}

            <div className="button-group">
                <button className="btn btn-success" onClick={handleSave}>Salvar</button>
                <button className="btn btn-secondary" onClick={onCancel}>Cancelar</button>
            </div>

            <div className="exam-section">
                <h4>ENVIAR PEDIDO DE EXAME OU RECEITA</h4>
                <div className="d-flex mb-3">
                    {options.map((option) => (
                        <label key={option} className="form-check-label me-3">
                            <input type="checkbox" className="form-check-input" value={option} checked={selectedOptions.includes(option)} onChange={() => handleCheckboxChange(option)} />
                            {option}
                        </label>
                    ))}
                </div>
                <div className="d-flex mb-3">
                    <button 
                        className={`btn btn-success me-2 ${paciente.telefone ? "" : "disabled"}`} 
                        onClick={() => EnviaExame(paciente.id, paciente.telefone)}
                    >
                        <i className="bi bi-whatsapp"></i> Enviar WhatsApp
                    </button>
                    <button 
                        className={`btn btn-primary ${paciente.email ? "" : "disabled"}`} 
                        onClick={() => EnviaExameEmail(paciente.id, paciente.empresa, paciente.nome, paciente.email)}
                    >
                        <i className="bi bi-mailbox"></i> Enviar Email
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PacientePreForm;
