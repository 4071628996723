import React, { useEffect, useState, useCallback } from "react";
import DataTable from "react-data-table-component";
import debounce from "lodash.debounce";
import api from "../../services/api.js";
import Navbar from "../../components/navbar/navbar";
import Menu from "../../components/menu/menuPaciente.jsx";
import PacienteForm from "./PacienteForm";
import "./paciente.css";

function Pacientes() {
  const [qtdRegPagina, setQtdRegPagina] = useState(10);
  const [pacientes, setPacientes] = useState([]);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [dadosPaciente, setDadosPaciente] = useState(null);
  const [busca, setBusca] = useState("");
  const [menuAberto, setMenuAberto] = useState(false);

  // Função para listar pacientes
  const listarPacientes = useCallback((busca, pagina = 1) => {
    api.get("/v1/paciente/listar", { params: { busca, pagina, qtd_reg_pagina: qtdRegPagina } })
      .then((resp) => {
        setTotalRegistros(resp.data.total_registros);
        setPacientes(resp.data.dados);
      })
      .catch(() => {
        setTotalRegistros(0);
        setPacientes([]);
        alert("Erro ao carregar pacientes");
      });
  }, [qtdRegPagina]);

  useEffect(() => {
    listarPacientes(busca, 1);
  }, [listarPacientes]);

  // Debounce na busca ao digitar
  const debouncedSearch = useCallback(
    debounce((valor) => {
      listarPacientes(valor, 1);
    }, 500),
    [listarPacientes]
  );

  function handleInputChange(event) {
    const valor = event.target.value;
    setBusca(valor);
    debouncedSearch(valor);
  }

  function handleSearchClick() {
    listarPacientes(busca, 1);
  }

  function toggleMenu() {
    setMenuAberto(!menuAberto);
  }

  function openFormPaciente(id) {
    if (id > 0) {
      api.get(`/v1/paciente/${id}`)
        .then((response) => {
          setDadosPaciente(response.data[0] || {});
        })
        .catch((err) => {
          console.log("Erro ao buscar paciente:", err);
        });
    } else {
      setDadosPaciente({
        id: 0,
        nome: "",
        cpf: "",
        telefone: "",
      });
    }
  }

  function closeFormPaciente() {
    setDadosPaciente(null);
  }

  function salvarPaciente(paciente) {
    if (paciente.id > 0) {
      api.put(`/v1/paciente/${paciente.id}`, paciente)
        .then(() => {
          closeFormPaciente();
          listarPacientes(busca, 1);
        })
        .catch(() => {
          alert("Erro ao salvar os dados.");
        });
    } else {
      api.post(`/v1/paciente`, paciente)
        .then(() => {
          closeFormPaciente();
          listarPacientes(busca, 1);
        })
        .catch(() => {
          alert("Erro ao salvar os dados.");
        });
    }
  }

  const columns = [
    { name: "ID", selector: (row) => row.id, sortable: true, width: "80px" },
    { name: "Nome", selector: (row) => row.nome, sortable: true },
    { name: "CPF", selector: (row) => row.cpf, sortable: true },
    { name: "Telefone", selector: (row) => row.telefone, sortable: true },
    {
      name: "Ações",
      cell: (row) => (
        <div className="table-actions">
          <button onClick={() => openFormPaciente(row.id)} className="btn-edit">
            <i className="bi bi-pencil-square"></i>
          </button>
        </div>
      ),
      width: "120px",
      right: true,
    },
  ];

  return (
    <div className="app-container">
      <Navbar />
      <button className="menu-toggle" onClick={toggleMenu}>
        {menuAberto ? "Fechar Menu" : "Abrir Menu"}
      </button>

      <div className="main-layout">
        <aside className={`sidebar ${menuAberto ? "open" : "closed"}`}>
          <menuPaciente page="paciente" />
        </aside>

        <main className="content">
          <div className="paciente-header">
            <h2>Pacientes</h2>
            <div className="header-search">
              <input
                type="text"
                className="search-input"
                placeholder="Buscar Paciente"
                value={busca}
                onChange={handleInputChange}
              />
              <button onClick={handleSearchClick} className="search-button">
                <i className="bi bi-search"></i>
              </button>
            </div>
            <button className="btn btn-success new-paciente-btn" onClick={() => openFormPaciente(0)}>
              Novo Paciente
            </button>
          </div>

          {!dadosPaciente ? (
            <div className="card">
              <DataTable
                columns={columns}
                data={pacientes}
                pagination
                paginationTotalRows={totalRegistros}
                highlightOnHover
                responsive
              />
            </div>
          ) : (
            <PacienteForm dados_paciente={dadosPaciente} onCancel={closeFormPaciente} onSave={salvarPaciente} />
          )}
        </main>
      </div>
    </div>
  );
}

export default Pacientes;
