// src/pages/login/Login.jsx
import React, { useState } from "react";
import "./login.css";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import api from "../../services/api.js";
import SaltPassword from "../../services/md5.js";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [erro, setErro] = useState("");
  const [loading, setLoading] = useState(false);

  function ProcessaLogin() {
    setLoading(true);
    api
      .post(`/v1/usuarios/login`, {
        email,
        senha,
        senha2: SaltPassword(senha)
      })
      .then((resp) => {
        setLoading(false);
        if (resp.data.status === "success") {
          const defaultEmpresa = resp.data.user.empresas.find(empresa => empresa.default === 1);
          const defaultEmpresaId = defaultEmpresa ? defaultEmpresa.id : null;
          const defaultEmpresaNome = defaultEmpresa ? defaultEmpresa.nome : null;
          localStorage.setItem('sessionToken', resp.data.token2);
          localStorage.setItem("id_usuario", parseInt(resp.data.user.id), 10);
          localStorage.setItem("id_empresa", parseInt(defaultEmpresaId),10);
          localStorage.setItem("empresa", defaultEmpresaNome);
          localStorage.setItem("usuario", resp.data.user.email);
          navigate("/dashboard");
        } else {
          setErro("Erro ao realizar login");
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.data.erro) {
          setErro(err.response.data.erro);
        } else {
          setErro("Erro ao realizar login");
        }
      });
  }

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-header">
          <img className="login-logo" src={logo} alt="Logo" />
          <h3 className="login-title">Login</h3>
        </div>
        <div className="login-body">
          <div className="p-field">
            <input
              type="email"
              className="p-inputtext p-component"
              placeholder="Login"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="p-field">
            <input
              type="password"
              className="p-inputtext p-component"
              placeholder="Senha"
              onChange={(e) => setSenha(e.target.value)}
            />
          </div>
          <div className="p-field">
            {loading ? (
              <button className="p-button p-component p-button-primary p-button-lg p-disabled">
                <span className="p-button-icon p-c pi pi-spin pi-spinner"></span>
                Acessando...
              </button>
            ) : (
              <button
                onClick={ProcessaLogin}
                className="p-button p-component p-button-primary p-button-lg"
                type="button"
              >
                Acessar
              </button>
            )}
          </div>
          {erro && (
            <div className="error-message">
              {erro}
            </div>
          )}
        </div>
        <div className="login-footer">
          <p>Suporte - INFOTECJF SISTEMAS</p>
        </div>
      </div>
    </div>
  );
}

export default Login;
