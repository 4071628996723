import axios from "axios";

const URL = "https://laserapp.com.br/dev/laserapp-api"


const api = axios.create({
    baseURL: URL, // URL base do backend
    timeout: 5000, // Timeout opcional
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export default api;