import React from "react";
import { PanelMenu } from "primereact/panelmenu";
import { useNavigate } from "react-router-dom";
import "./menu.css";

const Menu = () => {
  const navigate = useNavigate();

  const menuItems = [
    { label: "Dashboard", icon: "pi pi-fw pi-home", command: () => navigate("/dashboard") },
    { label: "Recepção", icon: "pi pi-fw pi-building", command: () => navigate("/dashboard") },
    {
      label: "Financeiro",
      icon: "pi pi-fw pi-dollar",
      items: [
        { label: "Pré", icon: "pi pi-fw pi-file", command: () => navigate("/dashboard") },
        { label: "Depósito", icon: "pi pi-fw pi-wallet", command: () => navigate("/dashboard") },
        { label: "Conciliação", icon: "pi pi-fw pi-chart-line", command: () => navigate("/dashboard") },
        { label: "Rotativo", icon: "pi pi-fw pi-refresh", command: () => navigate("/dashboard") },
        { label: "Relatórios", icon: "pi pi-fw pi-file", command: () => navigate("/dashboard") }
      ]
    },
    
    { label: "Contato", icon: "pi pi-fw pi-user", command: () => navigate("/contato") },
    { label: "Pré-Paciente", icon: "pi pi pi-fw pi-user-check-fw pi-user", command: () => navigate("/pacientepre") },

    {
      label: "Agenda",
      icon: "pi pi-fw pi-calendar",
      items: [
        { label: "Sanos", icon: "pi pi-fw pi-calendar-plus", command: () => navigate("/dashboard") },
        { label: "Glas", icon: "pi pi-fw pi-calendar-plus", command: () => navigate("/dashboard") },
        { label: "Dermato", icon: "pi pi-fw pi-calendar-plus", command: () => navigate("/dashboard") }
      ]
    },
    { label: "Pacientes", icon: "pi pi-fw pi-users", command: () => navigate("/paciente") },
    { label: "Confirmação", icon: "pi pi-fw pi-check", command: () => navigate("/dashboard") },
    { label: "Pós", icon: "pi pi-fw pi-heart", command: () => navigate("/dashboard") },
    { label: "CRM", icon: "pi pi-fw pi-briefcase", command: () => navigate("/dashboard") },
    { label: "Reclamações", icon: "pi pi-fw pi-exclamation-triangle", command: () => navigate("/dashboard") },
    { label: "Intercorrências", icon: "pi pi-fw pi-info-circle", command: () => navigate("/dashboard") },
    { label: "Usuários", icon: "pi pi-fw pi-user", command: () => navigate("/dashboard") },
    {
      label: "Perfil",
      icon: "pi pi-fw pi-cog",
      items: [
        { label: "Comissão", icon: "pi pi-fw pi-percentage", command: () => navigate("/dashboard") },
        { label: "Solicitações", icon: "pi pi-fw pi-envelope", command: () => navigate("/dashboard") }
      ]
    },
    { label: "Chat", icon: "pi pi-fw pi-comments", command: () => navigate("/dashboard") },
    { label: "Biblioteca", icon: "pi pi-fw pi-book", command: () => navigate("/dashboard") }
  ];

  return (
    <aside className="menu">
      <PanelMenu model={menuItems} style={{ width: "100%" }} />
    </aside>
  );
};

export default Menu;